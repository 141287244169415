import React, { Component } from 'react'
import profilePic from '../images/profilePic3.jpg';
import '../css/intro.css';

export default class Introduction extends Component {
  render() {
    return (
      <div>
        
            <div className="Introduction">
                
                <img src={profilePic} className='profilePic' alt="profilePic" />
                <p>Hello my name is Ross. I create behavior and functionality with code and internet technology tools. When I'm not working I'm spending time with my Wife, family and friends, playing sports, and enjoying the outdoors. </p>
                <p>Check the About section to get to know me a little more and also the Projects section to see some of my work.</p>
            </div>
        
      </div>
    )
  }
}