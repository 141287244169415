import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

export default class About extends Component {
  render() {
    return (
      <div>
          <ul className="mediaIconList">
              <li><a href="https://github.com/rossfletcher19" target="blank" ><FontAwesomeIcon icon={faGithub} /></a></li> 
          </ul>
                <div className="About">
                    <h5> A little about me.</h5>
                    <p>I’m based in the beautiful Pacific Northwest. I graduated from Oregon State University in 2009 with a Bachelor of Science degree in Business Administration. After college I spent around 8 years working in business operations, and in late 2017 I enrolled in a coding bootcamp and never looked back.</p>

                    <p>Currently I'm a Decisions Developer and I engineer, build, and troubleshoot business automation workflows and processes with <a href="https://decisions.com/" rel="noopener noreferrer" target="_blank">Decisions</a> workflow and rule platform.</p>

	                  <p>If you’d like to talk tech or have any questions about what I do or my journey to get here, I’d love to hear from you. Use the contact page and I’d be happy to get back to you.</p>

                </div>
        
      </div>
    )
  }
}